import { Tab, Nav } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchEventData } from "../../../actions/event_page/event_info"
import { memo } from "react"

import { useParams } from 'react-router-dom';
import { LoadingPage } from "../../shared/loading_page"
import EventForm from "../form/event_form"
import { PlayersTab } from "./players_tab/root"
import { closeEventRegistrations, openEventRegistrations } from "../../../actions/event_page/registration"
import { Tooltip } from "@mui/material"
import { NewPairingsForm } from "./pairings_tab/new"
import { PlayTab } from "./play_tab/root"

import { selectConfirmedPlayers } from "../../../selectors/event_page"
import { selectEventData, selectEventDataStatus } from "../../../selectors/event_page"
import { EventResultsTab } from "./event_results_tab/root";

const EventDashboard = () => {

  const dispatch = useDispatch()
  const { eventId } = useParams();

  const status = useSelector(selectEventDataStatus)
  const event = useSelector(selectEventData)
  const confirmedPlayers = useSelector(selectConfirmedPlayers)

  const getDefaultTab = () => {
    if(event.eventStageId === 5) {
      return 3
    }
    if(event.eventStageId > 1) {
      return 2
    }
    return 1
  }

  const [activeTab, setActiveTab] = useState(getDefaultTab())

  console.log(activeTab, event.eventStageId)
  console.log(getDefaultTab())
  useEffect(() => {
    dispatch(fetchEventData(eventId))
  }, [])

  useEffect(() => {
    setActiveTab(getDefaultTab())
  }, [event.eventStageId ])

  const switchRegistrationStatus = () => {
    if (event.registration_closed) {
      dispatch(openEventRegistrations(event.id))
    } else {
      dispatch(closeEventRegistrations(event.id))
    }
  }

  if (status === 'loading') return <LoadingPage/>
  if (status === 'success' || status === 'error')
    return (
      <div className="dashboard-container">
        <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
          <Nav className='custom-navbar' variant="pills">
            <Nav.Item>
              <Tooltip title='Editeaza detalii eveniment'>
                <Nav.Link eventKey={0} className="extra-bold">{event.name}</Nav.Link>
              </Tooltip>
            </Nav.Item>
            <Nav.Item>
              <Tooltip title='Editeaza lista de jucatori'>
                <Nav.Link eventKey={1}>Jucatori</Nav.Link>
              </Tooltip>
            </Nav.Item>
            { event.eventStageId === 1 && 
              <Nav.Item>
                <Tooltip title='Creeaza Grupele'>
                  <Nav.Link eventKey={7}>Incepe Turneul</Nav.Link>
                </Tooltip>
              </Nav.Item>
            }
            {
              event.eventStageId > 1 && 
                <Nav.Item>
                  <Tooltip title='Editeaza meciurile'>
                    <Nav.Link eventKey={2}>Faza de joc</Nav.Link>
                  </Tooltip>
                </Nav.Item>
            }
            {
              event.eventStageId === 5 && 
                <Nav.Item>
                  <Tooltip title='Editeaza Rezultate Finale'>
                    <Nav.Link eventKey={3}>Rezultate</Nav.Link>
                  </Tooltip>
                </Nav.Item>
            }
          </Nav>
          <Tab.Content className='event-dashboard-container' >
            <Tab.Pane className='event-tab-content info' eventKey={0}>
              <div className="center-content-h">
                <EventForm event = {event}></EventForm>
              </div>
            </Tab.Pane>
            <Tab.Pane className='event-tab-content registration' eventKey={1}>
              <PlayersTab/>
            </Tab.Pane>
            <Tab.Pane className='event-tab-content matchups' eventKey={7}>
              <NewPairingsForm nrOfPlayers={confirmedPlayers.length} eventId={event.id}/>
            </Tab.Pane>
            {
              event.eventStageId > 1 && 
              <Tab.Pane className='event-tab-content matchups' eventKey={2}>
                <PlayTab/>
              </Tab.Pane>
            }
            {
              event.eventStageId === 5 && 
              <Tab.Pane className='event-tab-content results' eventKey={3}>
                <EventResultsTab/>
              </Tab.Pane>
            }
          </Tab.Content>
        </Tab.Container>
      </div>
    );
}

export default memo(EventDashboard)