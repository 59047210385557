import { SERVER_URL } from '../server_config';
import { fetchWithToken } from '../helpers/auth';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const POTENTIAL_MANAGER_SUCCESS = 'POTENTIAL_MANAGER_SUCCESS';
export const POTENTIAL_MANAGER_FAILURE = 'POTENTIAL_MANAGER_FAILURE';
export const POTENTIAL_MANAGER_REQUEST = 'POTENTIAL_MANAGER_REQUEST';
export const POTENTIAL_MANAGER_RESET = 'POTENTIAL_MANAGER_RESET';

// Action Creators
export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const logoutRequest = () => ({
  type: LOGOUT_REQUEST,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutFailure = (error) => ({
  type: LOGOUT_FAILURE,
  payload: error,
});

export const registerRequest = () => ({
  type: REGISTER_REQUEST,
});

export const registerSuccess = (user) => ({
  type: REGISTER_SUCCESS,
  payload: user,
});

export const registerFailure = (error) => ({
  type: REGISTER_FAILURE,
  payload: error,
});

export const potentialManagerSuccess = (user) => ({
  type: POTENTIAL_MANAGER_SUCCESS,
  payload: user,
});

export const potentialManagerFailure = (error) => ({
  type: POTENTIAL_MANAGER_FAILURE,
  payload: error,
});

export const potentialManagerRequest = () => ({
  type: POTENTIAL_MANAGER_REQUEST,
});

export const potentialManagerReset = () => ({
  type: POTENTIAL_MANAGER_RESET,
});


// Thunk Action
export const login = (cred) => {
  return async (dispatch) => {
    dispatch(loginRequest());

    try {
      const response = await fetch(`${SERVER_URL}/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include', // Ensure cookies are sent
        body: JSON.stringify(cred),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      const user = data.user;
      const authorizationHeader = response.headers.get('Authorization') || response.headers.get('authorization');
      const token = authorizationHeader.split(' ')[1];

      // Store the token in localStorage
      localStorage.setItem('jwtToken', token);

      dispatch(loginSuccess(user));
    } catch (error) {
      dispatch(loginFailure(error.message));
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch(logoutRequest());

    fetchWithToken(`${SERVER_URL}/users/logout`, {
      method: "DELETE", // Typically, DELETE method is used for logout
      credentials: "include",
    }).then((response) => {
        if (!response.ok) {
          const errorData = response.json();
          throw new Error(errorData.error);
        }
        // Remove the token from localStorage
        localStorage.removeItem('jwtToken');
        window.location.href = '/';
        dispatch(logoutSuccess());
    }).catch((error) => {
      dispatch(logoutFailure(error.message));
    });
  };
};

export const register = (user) => {
  return async dispatch => {
    dispatch(registerRequest());
    fetch(`${SERVER_URL}/users`, {
      method: "POST",
      headers: {
        "Content-type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({user: user}),
    })
    .then((response) => {
      if (response.ok) {
        // the register route sends back an authorization header which is the jwt which should be used
        // while going through the rest of the website
        const authorizationHeader = response.headers.get('Authorization') || response.headers.get('authorization');
        const token = authorizationHeader.split(' ')[1];

        localStorage.setItem('jwtToken', token);

        return response.json();
      } else {
        return response.json().then((errorData) => {
          throw new Error(errorData.error);
        });
      }
    })
    .then((data) => {
      const user = data.user;

      dispatch(registerSuccess(user));
    })
    .catch((error) => {
      // Handle fetch errors or errors from the server
      // Dispatch a failure action or set an error message
      localStorage.removeItem('jwtToken');

      dispatch(registerFailure(error.message));
    });
  }
}

export const checkAuth = () => {
  return async (dispatch) => {
    fetch(`${SERVER_URL}/users/auth_status`, {
      method: "GET",
      headers: {
        "Content-type": "application/json"
      },
      credentials: "include",
    })
    .then((response) => {
      if (response.ok) {
        console.log(response)
        return response.json();
      } else {
        return response.json().then((errorData) => {
          throw new Error(errorData.error);
        });
      }
    })
    .then((data) => {
      console.log(data)
      if (data.signed_in) {
        const user = data.user;
        console.log(user)
        dispatch(loginSuccess(user));
      } else {
        dispatch(loginFailure("Not signed in"));
      }
    })
    .catch((error) => {
      dispatch(loginFailure(error.message));
    }); 
  }
}

export const getManager = (phone_number) => {
  return async (dispatch) => {
    const url = `${SERVER_URL}/users?role=&phone=${phone_number}`;

    try {
      const response = await fetchWithToken(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      // Parse the JSON response
      const data = await response.json();
      dispatch(potentialManagerSuccess(data));
    } catch (error) {
      dispatch(potentialManagerFailure(error.message));
    }
  };
};

export const resetPotentialManager = () => {
  return async (dispatch) => {
    dispatch(potentialManagerReset());
  }
}
