import { Button } from "react-bootstrap"

export const UpcomingEvent = ({event}) => {
  const redirectToEvent = () => {
    window.location.href = `/events/${event.id}`
  }

  const date = new Date(event.date);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are 0-based, so add 1
  const day = date.getDate();
  
  // Extract time
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  
  return (
    <div className="upcoming-event-container">
      <div className="extra-bold medium-text">
        {event.name}
      </div>
      <div className="small-text">
        {event.customer}
      </div>
      <div className="small-text margin-top-1">
        {formattedDate} | {formattedTime}
      </div>
      <div className="small-text">
        {event.county}
      </div>
      <div className="small-text margin-top-1">
        Jucatori: {event.confirmedPlayersCount}/{event.maxPlayers}
      </div>
      <div className="small-text">
        Lista de asteptare: {event.waitlistedPlayersCount}
      </div>
      { event.status &&
        <div className="small-text margin-top-1" style={{color: event.statusColor}}>
            Status: {event.status}
        </div>
      }
      <Button className="small-text pull-right pull-down details-button margin-top-1 bold" onClick={redirectToEvent}>Detalii</Button>
    </div>
  )
}